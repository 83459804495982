.hero-image-btn {
  width: 125px;
}

button.hero-image-btn {
  border: none;
}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.hero-image-container {
  margin-bottom: 50px;
  background-size: cover !important;
  position: relative;
}

.hero-overlay {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.hero-button-container {
  position: absolute;
  bottom: 24px;
}
