/* Footer */
.storefront-footer {
  background-color: #F5F8FA;
}

.storefront-footer .footer-content {
  color: #222632;
  font-size: 14px;
}

.storefront-footer div.box-disclaimer-text {
  border: 1px solid #697a8b;
}

.storefront-footer div.box-disclaimer-text p:last-of-type {
  margin-bottom: 0;
}

.storefront-footer .powered-by-revv-logo {
  width: 60px;
  position: relative;
  top: -1px;
}

.storefront-footer .disclaimer-terms a {
  text-decoration: none;
  color: #222632;
}