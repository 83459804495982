.storefront-link {
  margin: auto 0;
  color: #000;
  font-size: 25px;
  font-weight: normal;
}

.storefront-link:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .storefront-nav .rv-cta-button {
    width: 150px;
    height: 55px;
  }

  .storefront-nav .rv-button-secondary {
    height: 55px;
    width: 100px;
  }
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
}

@media (max-width: 991px) {
  .navbar-collapse {
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 5px;
    right: 0;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 15px;
    width: 90%;
    max-width: 600px;
    height: 100%;
    z-index: 1000;
  }

  .navbar-collapse.collapsing {
    position: fixed;
    right: -75%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    right: 0;
    transition: right 300ms ease-in-out;
  }

  .collapsing {
    height: 100%;
  }

  .collapsing {
    position: fixed !important;
    height: 100% !important;
    overflow: inherit !important;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: right 500ms ease-in-out;
  }
}

.navbar-nav-mobile,
.navbar-mobile .navbar-collapse {
  background-color: white !important;
}

.navbar-mobile .navbar-nav-header {
  padding: 1rem 1.3rem 2rem 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-weight: 600;
  color: grey;
  font-size: 20px;
}

.navbar-nav-mobile li {
  padding: 1rem 1rem 1rem 1rem;
  border-bottom: 1px solid #c3ccd5;
}

.navbar-mobile {
  padding-top: 0px !important;
}

.navbar-toggler-main {
  height: 55px;
}

.navbar-toggler-icon {
  font-size: 20px;
  color: white !important;
  font-weight: 600;
  border-color: lightgreen;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-nav-mobile .all-link {
  border-top: 1px solid #c3ccd5;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: rgb(245, 247, 250);
}

.navbar-nav-mobile li a.collection {
  color: #222632;
  font-weight: 600;
  border: none !important;
  font-size: 1.1rem;
}

.navbar-light .navbar-nav .nav-link.collection,
.navbar-light .navbar-nav .nav-link.collection:hover,
.navbar-light .navbar-nav .nav-link.collection-all,
.navbar-light .navbar-nav .dropdown-toggle,
.navbar-light .navbar-nav .nav-link.collection-all:hover {
  color: #222632;
}

.navbar-nav-mobile li.dropdown {
  background-color: white;
  padding: 0px;
}

.navbar-nav-mobile .dropdown-toggle::after {
  display: none;
}

.navbar-nav-mobile .dropdown-item {
  padding-left: 2.5rem !important;
}

.navbar-nav-mobile li.dropdown a {
  background-color: white;
  padding: 1rem 1rem 1rem 1rem;
}

.navbar-nav-mobile li.dropdown > a {
  padding: 1.6rem 1rem 1.6rem 1rem;
}

.navbar-nav-mobile .dropdown-menu {
  border-radius: 0px;
  border: none !important;
}

.navbar-nav-mobile li.dropdown > div,
.navbar-nav-mobile li.dropdown > div a {
  background-color: rgb(245, 247, 250);
}

.navbar-mobile .navbar-nav-header .navbar-toggler {
  font-size: 30px;
  padding: 0.3rem 1rem 0.5rem 1rem;
  color: #f8f9fa !important;
}
.storefront-logo .storefront-logo-image {
  display: block;
  max-width: 100%;
  max-height: 64px;
}

.navbar-desktop-container {
  display: flex;
  align-items: center;
}

.navbar-desktop li {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: clear;
}

.navbar-desktop li a {
  font-weight: 600;
  font-size: 0.9rem;
  border: none;
  background-color: transparent;
}

.navbar-desktop .collection {
  font-weight: 600;
}

.navbar-desktop .dropdown-menu a {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.navbar {
  padding: 0;
}

.navbar-expand .navbar-nav .nav-link {
  padding: 0 0.15rem 0 0.15rem !important;
}

.stay-open {
  display: block !important;
}
