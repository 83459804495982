/* Added To Cart */
.added-to-cart {
  border-radius: 5px;
  margin-bottom: 80px;
  min-height: 100px;
  border: 1px solid #31DD78;
}

.added-to-cart .cart-item-image {
  max-width: 50px;
}

.added-to-cart .item-options {
  text-transform: capitalize;
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
}

.added-to-cart .item-options .item-option {
  max-width: 100px;
  font-size: 14px;
}

#storefront.container .added-to-cart .rv-cta-button {
  height: 50px;
}

#storefront.container .added-to-cart .rv-button-secondary {
  min-width: 175px;
  color: #000;
  height: 50px;
}

#storefront.container .added-to-cart .rv-button-secondary:hover {
  color: #fff;
  background-color: gray;
}