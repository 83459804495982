/* Cart Items */
.cart-view {
  margin-bottom: 10rem;
}

.cart-view .cart-item {
  border: 1.5px solid #dee2e6;
  border-radius: 10px;
  min-height: 100px;
  text-transform: capitalize;
}

.cart-item .cart-item-image {
  max-width: 50px;
}

.item-option,
.remove-from-cart-button {
  font-size: 14px;
}

.cart-item .item-option {
  max-width: 100px;
}

.remove-from-cart-button {
  cursor: pointer;
  position: relative;
  top: -2px;
  color: #909298 !important;
}

.item-quantity {
  height: 52px;
  border-radius: 5px;
}

.item-quantity .item-quantity-selector {
  min-width: 55px;
}

.cart-item .product-price {
  color: #012040;
}

/* Order Summary */
.order-summary {
  box-shadow: 0 0 10px 0 rgba(39, 93, 186, 0.05), 0 10px 30px 0 rgba(19, 56, 121, 0.07);
}

.order-summary .card-title,
.order-summary .total {
  font-size: 14px;
  color: #697a8b;
  height: 48px;
  background-color: rgba(53, 75, 112, 0.04);
  background-color: rgba(53, 75, 112, 0.04);
}

.order-summary .subtotal,
.order-summary .shipping {
  font-size: 14px;
  color: #697a8b;
}

.order-summary .pull-right.strong {
  position: absolute;
  right: 5%;
  font-weight: bold;
  color: #000;
}

.order-summary .checkout-button .rv-cta-button {
  height: 50px;
}
