.product-image-container {
  width: 300px;
  height: 290px;
  background-color: white;
  border-radius: 6px;

  display: flex;
  align-items: center;
}

.product-tile .product-image-container:hover {
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (min-width: 0) {
  .product-tile .product-image-container {
    width: 132px;
    height: 132px;
  }
}

/* 
Arbitrary pixel amount that improves the look of the grid inbetween
xs and sm sizes
*/
@media (min-width: 460px) {
  .product-tile .product-image-container {
    width: 180px;
    height: 180px;
  }
}

/* 
Arbitrary pixel amount that improves the look of the grid inbetween
xs and sm sizes
*/
@media (min-width: 560px) {
  .product-tile .product-image-container {
    width: 212px;
    height: 212px;
  }
}

@media (min-width: 768px) {
  .product-tile .product-image-container {
    width: 240px;
    height: 236px;
  }
}

/* 
Arbitrary pixel amount that improves the look of the grid inbetween
lg and xl sizes
*/
@media (min-width: 1360px) {
  .product-tile .product-image-container {
    width: 300px;
    height: 290px;
  }
}

.product-tile .product-image {
  max-width: 100%;
  max-height: 100%;
}

.product-tile .product-price {
  font-size: 18px;
}

.product-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
