@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

html,
body,
body #root {
  height: 100% !important;
}

body #root > div {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #222632;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#storefront {
  flex: 1 0 auto;
}

.storefront-footer {
  flex-shrink: 0;
}

body h5 {
  font-size: 1.3rem;
}

@media (min-width: 1200px) {
  #storefront.container,
  .storefront-footer .container {
    max-width: 1340px;
  }
}

.container .rv-cta-button {
  background-color: #4362ff;
  border: none;
  height: 55px;
}

.container .rv-button-secondary {
  background-color: #fff;
  color: #4362ff;
  border-color: #fff;
  height: 55px;
}

.products-list {
  margin-bottom: 100px;
}

#storefront.container .featured-products-title {
  letter-spacing: -1px;
  color: #1e2b3a;
}

/* .products-list .product-row:after {
  content: "";
  flex: auto;
} */

.empty-products {
  width: 100%;
}
