.product-details .product-image-carousel .slide {
  border-radius: 6px;
  background-color: inherit;
  padding: 25px 0;
}

.product-details .product-image-carousel {
  width: 848px;
  max-width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(39, 93, 186, 0.05), 0 4px 8px 0 rgba(19, 56, 121, 0.07);
}

.product-details .image-container {
  max-width: 50%;
  border-radius: 6px;
}

.product-image-carousel .carousel li img {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.product-details .product-image {
  max-width: 100%;
}

.product-image-carousel .thumbs .thumb {
  border-radius: 6px;
  height: 100px;
  border: 2px solid #b2baca;
}

.product-image-carousel .thumbs-wrapper .thumbs {
  padding-left: 0;
}

.product-image-carousel .carousel .thumb.selected,
.product-image-carousel .carousel .thumb:hover {
  border: 2px solid #4362ff;
}
