.product-details {
  margin-bottom: 100px;
  margin-top: 50px;
}

.product-details .product-info .product-description {
  font-size: 16px;
}

.product-description {
  text-align: left;
}

.product-details .details-header {
  font-size: 36px;
}

.product-details .price {
  font-size: 24px;
}

.product-details .variant-selector select,
.product-details select.quantity-selector {
  height: 50px;
  background-color: #4362ff;
  color: #ffffff;
  border: none;
}

.product-details select.quantity-selector.disabled {
  background-color: gray;
  color: #fff;
}

.product-details .add-to-cart-button {
  background-color: #31dd78;
  height: 50px;
  border: none;
}

.product-details .add-to-cart-button:not(:disabled):hover {
  transition: all 0.3s ease;
  background-color: #31dd78;
  opacity: 0.5;
  /* box-shadow: 0 10px 30px; */
}

.product-details .add-to-cart-button.disabled {
  background-color: #fff;
  border: 1px solid #dee0e6;
  color: #000;
}

.product-details .error-message {
  color: #ff4d55;
}
